import energi from '../Images/energi.svg';
import konsumentverket from '../Images/konsumentverket.svg';

const Footer = () => {
  return (
    <>
      <footer className="w-full h-full mb-0 relative bg-[#B50156]">
        <div className="md:mx-auto md:max-w-[1440px] pb-8">
          <div className="sm:w-[90%] md:max-w-[649px] sm:pl-6 relative  top-9 text-white">
            <h2 className="font-bold text-3xl leading-8 mb-3">Om Bilsvar</h2>
            <p className="font-normal text-lg leading-8">
              Bilsvar.se var en oberoende, kostnadsfri tjänst där du kunde
              jämföra olika bilmodeller avseende ekonomi, miljö, säkerhet och
              teknik.
            </p>
          </div>

          <div className="md:w-full relative mt-16 sm:pl-6 sm:w-[90%]">
            <h3 className="font-bold text-2xl leading-8 text-white mb-[18px]">
              Tjänsten var ett samarbete mellan
            </h3>

            <div className="md:flex gap-4 w-full h-full">
              <div className="sm:w-64 sm:h-32 md:w-56 md:h-32 bg-white rounded-lg">
                <img
                  className="mx-auto sm:pt-6 md:mt-1 mt-6 md:w-48 sm:w-56"
                  src={energi}
                  alt="Energimyndigheten logotyp"
                />
              </div>

              <div className="sm:w-64 sm:h-32 md:w-56 md:h-32 bg-white rounded-lg">
                <img
                  className="mx-auto sm:pt-[0.8rem] md:pt-0 md:mt-[1.2rem] mt-6 md:w-48 sm:w-56"
                  src={konsumentverket}
                  alt="Konsumentverket logotyp"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
