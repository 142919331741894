import arrow from '../Images/arrow.svg';
import hallaKonsument from '../Images/hallaKonsument.svg';

const Header = () => {
  return (
    <>
      <header className="w-full h-full">
        <div className="md:max-w-[1440px] md:mx-auto">
          <div className="h-24 sm:ml-[18px] sm:mt-12 md:mt-0">
            <a
              className="md:w-56 h-8 top-4 sm:mt-[-3rem] md:mt-0 relative float-right mb-2 sm:mr-8"
              aria-label="Externlänk till hallåkonsument webbplats"
              href="https://www.hallakonsument.se/"
            >
              <div className="flex gap-2 sm:mt-3 md:mt-0">
                <p className="font-medium hover:underline text-lg leading-8  text-[#0061C2]">
                  Till Hallå konsument
                </p>
                <img
                  className="sm:mr-[-10px] md:mr-0"
                  src={arrow}
                  alt="En pil som pekar åt höger"
                />
              </div>
            </a>
            <img
              className="relative sm:w-64 sm:h-24 top-4 z-10 md:ml-4"
              src={hallaKonsument}
              alt="Hallåkonsument logotyp"
            />
          </div>
        </div>
        <div className="w-full h-20 bg-[#162259] ">
          <div className="relative h-12 top-4 sm:ml-[18px] md:max-w-[1440px] md:mx-auto">
            <span className="relative font-medium text-3xl leading-10 md:ml-11 md:ml-22 text-[#FFFFFF]">
              Bilsvar
            </span>
          </div>
          <div className="w-full h-2 bg-[#B50156] relative bottom-[-1.5rem]" />
        </div>
      </header>
    </>
  );
};

export default Header;
