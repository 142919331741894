import Footer from '../components/Footer';
import Header from '../components/Header';
import Main from '../components/Main';

const Landing = () => {
  document.title = 'Startsida | Bilsvar';
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
};

export default Landing;
