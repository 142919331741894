import linkIcon from '../Images/linkIcon.svg';

const Main = () => {
  return (
    <>
      <main className="md:max-w-[1440px] md:mx-auto w-full mb-auto ">
        <div className="sm:mr-2 sm:ml-2 md:w-[768px] bg-[#83726E] mt-4 md:mt-8 mb-11 relative ">
          <div className="sm:mx-auto p-10 sm:p-4">
            <h1 className="font-bold text-5xl w-full leading-[56px] sm:leading-[48px] sm:text-[36px] text-white relative ">
              Denna webbplats stängdes ner 15 december 2022
            </h1>
            <div className="pt-4 md:w-[684px]">
              <p className="font-normal text-lg leading-8 mb-4 text-white relative">
                Information om att köpa bil hittar du i stället hos
                Konsumentverkets upplysningstjänst Hallå konsument.
              </p>
              <p className="font-normal text-lg leading-8 text-white relative">
                {' '}
                På hallåkonsument.se hittar du bra tips om vad du som konsument
                kan tänka på när du ska köpa bil. Där hittar du också
                information om hur bilar och andra fordon påverkar klimatet och
                vad du kan göra för att minska bilens påverkan på klimatet.
              </p>
            </div>

            <div className="mt-6 md:w-[65%]">
              <a
                aria-label="Läs mer information om att köpa bil på hallå konsument webbplats"
                href="https://www.hallakonsument.se/omrade/kopa-bil/"
              >
                <p className="mb-6 sm:mt-8 md:mt-0 font-medium hover:underline text-white leading-8 text-lg relative sm:leading-[32px] sm:text-[18px]">
                  Information om att köpa bil hos Hallå konsument
                  <img
                    className="inline-block ml-2"
                    src={linkIcon}
                    alt="Externlänk icon"
                  />
                </p>
              </a>

              <a
                aria-label="Läs mer om information om bilar och miljö på hallå konsument webbplats"
                href="https://www.hallakonsument.se/omrade/bil-och-miljo/"
              >
                <p className="font-medium sm:mb-6 hover:underline text-white leading-8 text-lg relative inline-block sm:leading-[32px] sm:text-[18px] ">
                  Om bilar och miljö hos Hallå konsument
                  <img
                    className="inline-block ml-2"
                    src={linkIcon}
                    alt="Externkänk icon"
                  />
                </p>
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Main;
