import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const ErrorPage404 = () => {
  document.title = '404 | Bilsvar';
  return (
    <>
      <Header />
      <div className="text-center mb-auto">
        <div className="mt-10 p-3 mb-6 rounded-full w-48 h-48 bg-[#fbf1f6] mx-auto">
          <span
            className=" items-center mx-auto w-[10.5rem] h-[10.5rem]
          rounded-full flex text-[#B50156] justify-center text-3xl font-bold bg-[#f7e4ed]"
          >
            Oj då!
          </span>
        </div>
        <p className="text-[#161616] font-bold text-center text-3xl">
          Tyvärr, vi kan inte hitta sidan du letar efter
        </p>
        <Link to="/">
          <p className="text-[#B50156] text-center font-normal">
            Klicka här för att komma till startsidan
          </p>
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage404;
